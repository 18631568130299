import { FC } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Namespace } from '@src/i18n/types';
import { useTranslation } from 'react-i18next';

const AboutBrand: FC = () => {
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'About-brand' });

    const cards = [
        {
            title: t('ONmacabim – Judean Desert Herbs LTD'),
            description: t('The company manages the full cycle of product creation:'),
            img: 'assets/about-brand/brand-1.webp',
            items: [
                t('Control of cultivation of medicinal plants'),
                t('Collection and processing, development of drug formulas'),
                t('Testing, licensing and production on advanced equipment')
            ]
        },
        {
            title: `${t('Advantages and features of ONmacabim cosmetics')}:`,
            img: 'assets/about-brand/brand-2.jpeg',
            items: [
                t('Possibility of solving several cosmetic problems in one procedure'),
                t('The advantage of using drugs from different lines in one procedure'),
                t('Possibility of an individual approach to each client, drawing up'),
                t('Absolute safety in use'),
                t('Compatibility with hardware techniques')
            ]
        },
        {
            title: `${t('Active Ingredients')}:`,
            img: 'assets/about-brand/brand-3.jpg',
            items: [
                t('Plant extracts obtained by special patented extraction methods in high concentrations'),
                t('The compositions of cosmetic products do not contain mineral oils, chemical dyes, parabens and heavy metal salts')
            ]
        }
    ];

    return (
        <div className='flex flex-col gap-16 md:gap-32 my-24 px-8'>
            {cards.map((item, index) => (
                <div key={item.title} className={`flex justify-evenly md:gap-6 lg:gap-8 items-center flex-col lg:flex-row ${index % 2 ? 'lg:flex-row-reverse' : ''}`}>
                    <img
                        src={item.img}
                        className='border rounded-xl h-[335px] w-[500px] object-cover' alt=''
                    />
                    <div className='mt-12 lg:mt-0'>
                        <p className='text-4xl text-primary-200 mb-6'>{item.title}</p>
                        {item?.description ? <p className='text-[20px] mt-3 text-[#626262] w-[70%] lg:w-auto mx-auto'>{item.description}</p> : null}
                        <ul className='flex flex-col gap-6 mt-8 mx-auto lg:mx-0'>
                            {item.items.map((i) => (
                                <li key={i}>
                                    <div className='flex items-center sm:pl-[10%] lg:pl-0 justify-start gap-4'>
                                        <div className='size-5'><IoIosCheckmarkCircle className='text-primary-200' /></div>
                                        <span className='text-start text-xl sm:text-2xl'>{i}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AboutBrand;
