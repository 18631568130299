import { ERoutes, NavigationItem } from '@constants/interfaces';

export const NavigationItems: NavigationItem[] = [
    {
        path: ERoutes.Home,
        title: 'Home',
    },
    {
        path: ERoutes.Catalog,
        title: 'Catalog',
    },
    {
        path: ERoutes.AboutUs,
        title: 'About Us',
    },
    {
        path: ERoutes.AboutBrand,
        title: 'About brand',
    },
    {
        path: ERoutes.Contact,
        title: 'Contacts',
    },
];