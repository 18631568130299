import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLanguageSelect() {
    const [searchParams] = useSearchParams();
    const { i18n } = useTranslation();

    const lang = useMemo(() => searchParams.get('lang'), [searchParams]);

    useEffect(() => {
        if (lang) i18n.changeLanguage(lang);
    }, [lang]);
}