import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@components/Spinner';
import { CatalogOverviewData } from '@pages/interfaces';
import { ERoutes } from '@constants/interfaces';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@src/i18n/types.ts';

const CatalogOverview: FC = () => {
    const { title } = useParams();

    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: `Catalog.${title}` });

    const { data: catalogOverviewData, isLoading } = useQuery<CatalogOverviewData>({
        queryKey: [`catalog/${title}`],
        queryFn: async () => {
            const resp = await fetch(`/data/catalog/${title}/info.json`);
            return await resp.json();
        },
    });

    return (
        <div className='py-20 px-20 xl:px-0'>
            <h2 className='uppercase pb-10 font-bold tracking-wide text-primary-100 text-4xl sm:text-5xl'>{title}</h2>
            {isLoading ? <Spinner /> : (
                <>
                {catalogOverviewData?.description ? <h4 className="pb-16 tracking-wide">{t(catalogOverviewData.description)}</h4> : null}
                    <div className='grid pb-0 md:pb-20 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16'>
                        {catalogOverviewData?.data?.map(product => (
                            <div className='flex flex-col' key={product.title}>
                                <Link to={`${ERoutes.Catalog}/${title}/${product.id}`}>
                                    <div className='group relative cursor-pointer'>
                                        <img className='border-2 border-gray-100 rounded-xl transition-all ease-in-out duration-500 w-full group-hover:brightness-[.65]' src={product.img} alt='Product' />
                                        <span className='w-[90%] uppercase transition-all ease-in-out duration-500 tracking-wide text-white bg-primary-100 px-2.5 py-1 rounded-lg right-[5%] absolute bottom-[50px] group-hover:bottom-[85px]'>
                                            {t(product.title)}
                                        </span>
                                        <div className='transition-all ease-in-out duration-1000 h-px w-[70%] mx-auto bg-white/90 relative bottom-[60px] opacity-0 group-hover:opacity-100'/>
                                    </div>
                                </Link>
                                <p className='mt-8 pt-1 px-2 leading-normal tracking-wide font-medium'>{t(product.description)}</p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default CatalogOverview;