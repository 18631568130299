import { AppRoutes } from '@constants/routes';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useGA } from '@src/hooks/useGA';
import { useEffect } from 'react';
import ScrollTop from '@components/ScrollTop';
import LanguageSelector from '@components/LanguageSelector';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import useLanguageSelect from '@src/hooks/useLanguageSelect.tsx';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
});

function App() {
    const { initGA, screenGA } = useGA();
    const location = useLocation();

    useLanguageSelect();

    useEffect(() => {
        initGA('G-8YXPDNXFGW');
    }, []);

    useEffect(() => {
        if (location.pathname) {
            window.scrollTo(0, 0);
            screenGA(location.pathname);
        }
    }, [location]);

    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                {AppRoutes.map(route => <Route key={route.path} path={route.path} element={route.element} />)}
            </Routes>
            <LanguageSelector />
            <ScrollTop />
            <ToastContainer position="top-center" autoClose={5000} closeOnClick pauseOnHover />
        </QueryClientProvider>
    );
}

export default App;
