import { FC } from 'react';
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Namespace } from '@src/i18n/types';
import { useTranslation } from 'react-i18next';

const AboutUs: FC = () => {
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'About-us' });
    const navigate = useNavigate();

    return (
        <div className='grid grid-rows-1 lg:grid-cols-2 lg:grid-rows-none px-8 py-16 gap-8'>
            <div className='max-w-xl mx-auto lg:my-auto lg:mx-0 grid gap-12 lg:gap-4'>
                <div>
                    <p className='text-[40px] text-primary-200'>ON Floral Breeze</p>
                    <p className='text-3xl text-[#626262] mt-8'>
                        {t('Official representative of the cosmeceutical company ONmacabim in Greece')}
                    </p>
                    <ul className='flex flex-col gap-6 mt-8 sm:text-lg md:text-xl lg:text-2xl mx-0'>
                        <li>
                            <div className='flex justify-start items-center gap-4 text-start'>
                                <div className='size-5'><FaArrowAltCircleRight className='text-primary-200' /></div>
                                <span>{t('Possibility of an individual approach to each client')}</span>
                            </div>
                        </li>
                        <li>
                            <div className='flex justify-start items-center gap-4 text-start'>
                                <div className='size-5'><FaArrowAltCircleRight className='text-primary-200' /></div>
                                <span>{t('The advantage of using drugs from different lines in one procedure')}</span>
                            </div>
                        </li>
                        <li>
                            <div className='flex justify-start items-center gap-4 text-start'>
                                <div className='size-5'><FaArrowAltCircleRight className='text-primary-200' /></div>
                                <span>{t('Absolute safety in use')}</span>
                            </div>
                        </li>
                    </ul>
                    <p className='mt-8 text-start'>
                        {t('The Onmacabim branded portal allows you not only to study products and place an order, but also opens up unlimited access for specialists to the knowledge accumulated over decades in the fieldof cosmetology and modern research by practicing specialists')}
                    </p>
                    <p className='mt-4 text-start'>
                        {t('To access more information, place an order, and view accumulated bonuses')}
                    </p>
                </div>
                <div className='flex items-start lg:mt-8'>
                    <button
                        onClick={() => navigate('/contact')}
                        className='transition-colors duration-300 ease-in-out bg-primary-200 hover:bg-primary-300 text-white px-6 py-3 font-xl rounded-full sm:mb-0 disabled:opacity-70'
                    >
                                {t('Connect with us')}
                    </button>
                </div>
            </div>
            <div className='hidden lg:flex w-full items-center justify-center'>
                <img
                    className='w-fit h-[85%]'
                    src='assets/about-us/main.jpeg'
                />
            </div>
        </div>
    );
};

export default AboutUs;