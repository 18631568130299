import { FC } from 'react';
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { LuClock } from "react-icons/lu";
import { Namespace } from '@src/i18n/types';
import { useTranslation } from 'react-i18next';

const ContactInfo: FC = () => {
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'Contacts' });

    return (
        <div className='h-full pr-6 p-5 md:p-12'>
            <ul className='mb-6 grid h-full md:mb-0'>
                <li className='flex items-center justify-start gap-6 max-w-lg'>
                    <div className='flex h-10 w-10 items-center justify-center rounded bg-primary-100 text-white'>
                        <BsFillTelephoneOutboundFill />
                    </div>
                    <div className='ml-4 mb-4 flex flex-col items-center justify-center grow'>
                        <h3 className='mb-2 text-lg font-medium leading-6 text-primary-100'>{t('Contact')}</h3>
                        <p className='group text-gray-500 cursor-pointer'>{t('Mobile')}:
                            <a href='tel:+306996104840' className='ml-2 group-hover:text-primary-100'>+30 699 6104840</a>
                        </p>
                        <p className='group text-gray-500 cursor-pointer'>{t('Email')}:
                            <a href='mailto:svetapopova77@icloud.com' className='ml-2 group-hover:text-primary-100'>svetapopova77@icloud.com</a>
                        </p>
                    </div>
                </li>
                <li className='flex items-center justify-start gap-6'>
                    <div className='flex h-10 w-10 items-center justify-center rounded bg-primary-100 text-white'>
                        <LuClock />
                    </div>
                    <div className='ml-4 mb-4 flex flex-col items-center justify-center grow'>
                        <h3 className='mb-2 text-lg font-medium leading-6 text-primary-100'>
                            {t('Working hours')}
                        </h3>
                        <p className='text-gray-500'>{t('Monday - Saturday')}: 10:00 - 19:00</p>
                        <p className='text-gray-500'>{t('Sunday')}: 11:00 - 18:00</p>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default ContactInfo;