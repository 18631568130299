import { FC } from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

const Footer: FC = () => {
    return (
        <div className='w-full flex justify-between py-12 px-4 xs:px-6 sm:px-12 xl:px-2 border-t border-gray-400'>
            <a href='tel:+306996104840' className='flex gap-2 items-center'>
                <BsFillTelephoneFill />
                +30 699 6104840
            </a>
            <div className='flex items-center gap-8'>
                <a href="https://www.facebook.com/svetlana.german1/" target='_blank'>
                    <FaFacebookSquare className='cursor-pointer'/>
                </a>
                <a href="http://instagram.com/aesthetic_face_body/" target='_blank'>
                    <FaInstagram className='cursor-pointer' />
                </a>
            </div>
        </div>
    );
};

export default Footer;