import { useTranslation } from 'react-i18next';
import { Language } from '@src/i18n/types';
import { useEffect, useState } from 'react';
import { languageArray } from '@src/i18n/i18n';

const languageNames = {
    [Language.EL]: 'Ελληνικά',
    [Language.RU]: 'Русский',
    [Language.EN]: 'English'
};

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(i18n.language as Language);
    const [isOpenedSelector, setIsOpenedSelector] = useState<boolean>(false);

    useEffect(() => {
        if (selectedLanguage && i18n) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, [selectedLanguage, i18n]);

    const handleSelectLanguage = (lang: Language) => {
        setSelectedLanguage(lang);
        setIsOpenedSelector(false);
    };

    const getStyles = (idx: number) => {
        if (idx === languageArray.length - 1) {
            return 'rounded-b-lg';
        }

        if (!idx) {
            return 'rounded-t-lg';
        }

        return '';
    };

    return (
        <>
            <div className='fixed right-[40px] bottom-[80px] z-10'>
                {isOpenedSelector ? (
                    <div className='bg-white rounded-lg shadow-[0_9px_25px_0_rgba(132,128,177,0.28)] border border-gray-100'>
                        {languageArray.map((lang, i) => (
                            <div
                                className={`flex items-center gap-4 cursor-pointer ${getStyles(i)} hover:bg-gray-200 px-2.5 py-2`}
                                onClick={() => handleSelectLanguage(lang)}
                            >
                                <img className="w-5 h-4" src={`/assets/flags/${lang}.svg`} alt="Language" />
                                <span>{languageNames[lang]}</span>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
            <div className='fixed right-[40px] bottom-[40px] z-10'>
                <div
                    className='bg-white rounded-lg shadow-[0_9px_25px_0_rgba(132,128,177,0.28)] px-2.5 py-2 cursor-pointer'
                    onClick={() => setIsOpenedSelector(prev => !prev)}
                >
                    <img className="w-5 h-4" src={`/assets/flags/${selectedLanguage}.svg`} alt="Language" />
                </div>
            </div>
        </>
    );
};

export default LanguageSelector;