import { FC, useCallback, useEffect, useMemo } from 'react';
import Input from '@components/Input';
import TextArea from '@components/TextArea';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import EmailJS from '@emailjs/browser';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { Namespace } from '@src/i18n/types';
import { useTranslation } from 'react-i18next';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ContactForm: FC = () => {
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'Contacts' });

    const [searchParams] = useSearchParams();

    const product = useMemo(() => searchParams.get('product'), [searchParams]);
    const catalog = useMemo(() => searchParams.get('catalog'), [searchParams]);

    const schema = useMemo(() => yup.object({
        name: yup.string().min(3, t('Must be at least 3 characters long')).max(40, t('Name is too long')).trim().required(),
        email: yup.string().trim().email().required(t('Email is required')),
        phoneNumber: yup.string().matches(phoneRegExp, t('Phone number is not valid')).required(),
        message: yup.string().trim(),
    }), []);

    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = form;

    const onSubmit: SubmitHandler<any> = useCallback((data) => {
        console.log(data);
        EmailJS.send('service_lfgxnto','template_ogdq8la', data, { publicKey: 'ccDCsL0BOWeJAmzVC' })
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                toast.success(t('Submitted successfully'));
            }, (err) => {
                console.log('FAILED...', err);
                toast.error(t('Failed to submit'));
            });
        form.reset();
    }, []);

    useEffect(() => {
        if (product && catalog) {
            form.setValue('message', `${t('Catalog')} - ${catalog}\n${t('Product')} - ${product}\n`);
        }
    }, [product, catalog]);

    return (
        <FormProvider {...form}>
            <div className='card h-fit max-w-6xl p-5 md:p-12' id='form'>
                <h2 className='mb-4 text-2xl font-bold text-primary-100'>{t('Contact Us')}</h2>
                <form id='contactForm' onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-6'>
                        <div className='mx-0 mb-1 sm:mb-4'>
                            <Input
                                type='text'
                                placeholder={t('Your name')}
                                autoComplete='given-name'
                                label={`${t('Name')} *`}
                                isError={!!errors?.name}
                                error={errors?.name}
                                {...register('name')}
                            />
                            <Input
                                type='email'
                                placeholder={t('Your email address')}
                                autoComplete='email'
                                label={`${t('Email')} *`}
                                isError={!!errors?.email}
                                error={errors?.email}
                                {...register('email')}
                            />
                            <Input
                                type='tel'
                                placeholder={t('Your phone number')}
                                autoComplete='tel'
                                label={`${t('Phone number')} *`}
                                isError={!!errors?.phoneNumber}
                                error={errors?.phoneNumber}
                                {...register('phoneNumber')}
                            />
                        </div>
                        <TextArea
                            placeholder={`${t('Write your message')}...`}
                            label={t('Message')}
                            isError={!!errors?.message}
                            error={errors?.message}
                            {...register('message')}
                        />
                    </div>
                    <div className='text-center'>
                        <button
                            type='submit'
                            className='transition-colors duration-300 ease-in-out w-full bg-primary-200 hover:bg-primary-300 text-white px-6 py-2 font-xl rounded-md sm:mb-0 disabled:opacity-70'
                        >
                            {t('Send')}
                        </button>
                    </div>
                </form>
            </div>
        </FormProvider>
    );
};

export default ContactForm;