import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Language, Namespace } from './types';

const languageDetector = new LanguageDetector();

export const DEFAULT_LANG = Language.EN;

languageDetector.addDetector({
    name: 'defaultLangDetector',
    lookup(): string {
        return DEFAULT_LANG;
    },
});

const loadPath = `/locales/{{lng}}/{{ns}}.json?cache=${Math.random()}`;
const addPath = '/locales/add/{{lng}}/{{ns}}';

export const languageArray = Object.values(Language);

i18n
    .use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath,
            addPath,
        },
        fallbackLng: DEFAULT_LANG,
        supportedLngs: languageArray,
        ns: Object.values(Namespace),
        defaultNS: Namespace.TRANSLATION,
        fallbackNS: Namespace.TRANSLATION,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
        detection: {
            order: ['localStorage', 'defaultLangDetector'],
        },
    });

export default i18n;
