import { ERoutes } from '@constants/interfaces';
import { PathRouteProps } from 'react-router';
import AboutUs from '@pages/AboutUs';
import AboutBrand from '@pages/AboutBrand';
import Home from '@pages/Home';
import CatalogOverview from '@pages/CatalogOverview';
import Catalog from '@pages/Catalog';
import Contact from '@pages/Contact';
import { Navigate } from 'react-router-dom';
import Header from '@components/Header';
import NavMenu from '@components/NavMenu';
import Footer from '@components/Footer';
import CatalogOverviewItem from '@pages/CatalogOverviewItem';

const bootstrapRoutes = (routes: PathRouteProps[]): PathRouteProps[] => routes.map(r => ({
    ...r,
    element: (
        <>
            <Header />
            <NavMenu />
            {r.element}
            <Footer />
        </>
    ),
}));

const routes: PathRouteProps[] = [
    {
        path: ERoutes.Home,
        element: <Home />,
    },
    {
        path: ERoutes.Catalog,
        element: <Catalog />,
    },
    {
        path: ERoutes.CatalogOverview,
        element: <CatalogOverview />,
    },
    {
        path: ERoutes.CatalogOverviewItem,
        element: <CatalogOverviewItem />,
    },
    {
        path: ERoutes.AboutUs,
        element: <AboutUs />,
    },
    {
        path: ERoutes.AboutBrand,
        element: <AboutBrand />,
    },
    {
        path: ERoutes.Contact,
        element: <Contact />,
    },
];

export const AppRoutes: PathRouteProps[] = [
    ...bootstrapRoutes(routes),
    {
        path: ERoutes.NotFound,
        element: <Navigate to='/' replace />,
    },
];