import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

const screenGA = (page: string) => {
    ReactGA.send({ hitType: "pageview", page });
};

const eventGA = (inputFields: UaEventOptions) => {
    const baseFields: UaEventOptions = {
        category: "your category",
        action: "your action",
        label: "your label",
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
    };

    ReactGA.event({
        ...baseFields,
        ...inputFields
    });
}

const initGA = (tackingId: string) => {
    ReactGA.initialize(tackingId);
};


export const useGA = () => ({
    initGA,
    eventGA,
    screenGA
});