import { forwardRef } from 'react';
import { ChangeHandler } from 'react-hook-form';

type TextAreaProps = {
    onChange: ChangeHandler;
    placeholder: string;
    label: string;
    isError: boolean;
    error: any;
    name: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ onChange, placeholder, label, name, error, isError, ...props }, ref) => {
    return (
        <div className='mx-0 mb-1 sm:mb-4 flex flex-col items-baseline'>
            <label htmlFor={name} className='pb-2 text-sm uppercase tracking-wider text-primary-100'>{label}</label>
            <textarea
                id={name}
                ref={ref}
                name={name}
                cols={30}
                rows={5}
                placeholder={placeholder}
                className='mb-2 w-full rounded-md focus:outline-none focus:ring focus:ring-primary-100 bg-white border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0'
                {...props}
            />
            {isError ? <p className='mt-2 text-red-700'>{error.message}</p> : null}
        </div>
    );
});

export default TextArea;