import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@components/Spinner';
import { CatalogOverviewItemData } from '@pages/interfaces';
import { ERoutes } from '@constants/interfaces';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@src/i18n/types.ts';

const CatalogOverviewItem: FC = () => {
    const { title, id } = useParams();

    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: `Catalog.${title}` });
    const { t: commonTranslation } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'Catalog' });

    const { data: catalogOverviewItemData, isLoading } = useQuery<CatalogOverviewItemData>({
        queryKey: [`catalog/${title}/${id}`],
        queryFn: async () => {
            const resp = await fetch(`/data/catalog/${title}/${id}.json`);
            return await resp.json();
        },
    });

    return (
        <div className='p-6 sm:p-12 lg:py-6 xl:px-0'>
            {isLoading ? <Spinner /> : (
                <div className='grid lg:grid-cols-2 lg:place-items-center px-2 py-6 md:py-16'>
                    {catalogOverviewItemData?.title ?
                        <h3 className='lg:hidden text-4xl text-primary-100 font-medium mb-8'>{t(catalogOverviewItemData.title)}</h3> : null}
                    <img
                        className='border-2 border-gray-100 rounded-xl transition-all ease-in-out duration-500 w-full group-hover:brightness-[.65] mb-8 lg:mb-0'
                        src={catalogOverviewItemData?.img} alt='Product' />
                    <div className='flex flex-col gap-5 px-6 lg:px-16 items-start text-2xl'>
                        {catalogOverviewItemData?.title ? <span
                            className='text-[30px] text-primary-100 font-medium mb-6 hidden lg:block'>{t(catalogOverviewItemData.title)}</span> : null}
                        {catalogOverviewItemData?.size ? (
                            <div>
                                <span className='font-bold'>{commonTranslation('Size')}: </span>
                                <span>{commonTranslation("{{size}} ml", { size: catalogOverviewItemData.size.replace(' ml', '') })}</span>
                            </div>
                        ) : null}
                        {catalogOverviewItemData?.effect ? (
                            <div className='text-start'>
                                <span className='font-bold'>{commonTranslation('Effect')}: </span>
                                <span className="first-letter:uppercase">{t(catalogOverviewItemData.effect)}</span>
                            </div>
                        ) : null}
                        {catalogOverviewItemData?.ingredients ? (
                            <div className='text-start'>
                                <span className='font-bold'>{commonTranslation('Ingredients')}: </span>
                                <span className="first-letter:uppercase">{t(catalogOverviewItemData.ingredients)}</span>
                            </div>
                        ) : null}
                        {catalogOverviewItemData?.application ? (
                            <div className='text-start'>
                                <span className='font-bold'>{commonTranslation('Application')}: </span>
                                <span className="first-letter:uppercase">{t(catalogOverviewItemData.application)}</span>
                            </div>
                        ) : null}
                        <div className='flex items-start mt-6 lg:mt-8'>
                            <Link to={`${ERoutes.Contact}?catalog=${title}&product=${catalogOverviewItemData?.title}`}
                                  target='_blank'>
                                <button
                                    className='transition-colors duration-300 ease-in-out bg-primary-200 hover:bg-primary-300 text-white px-16 py-3 text-3xl rounded-full sm:mb-0 disabled:opacity-70'
                                >
                                    {commonTranslation('Order')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CatalogOverviewItem;