import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { ERoutes } from '@constants/interfaces';
import Spinner from '@components/Spinner';
import { CatalogData } from '@pages/interfaces';
import { Namespace } from '@src/i18n/types';
import { useTranslation } from 'react-i18next';

const Catalog: FC = () => {
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'Catalog' });

    const { data: catalogData, isLoading } = useQuery<CatalogData>({
        queryKey: ['catalog'],
        queryFn: async () => {
            const resp = await fetch('/data/catalog/info.json');
            return await resp.json();
        },
    });

    return (
        <div className='pb-40 pt-20 px-20 xl:px-0'>
            <h2 className='pb-20 font-bold tracking-wide text-primary-100 text-3xl sm:text-5xl'>{t('Catalog')}</h2>
            {isLoading ? <Spinner /> : (
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-16'>
                    {catalogData?.map(product => (
                        <div className='flex flex-col' key={product.title}>
                            <Link to={`${ERoutes.Catalog}/${product.title}`}>
                                <div className='group relative cursor-pointer'>
                                    <img
                                        className='border-2 border-gray-100 rounded-xl transition-all ease-in-out duration-500 w-full group-hover:brightness-[.65]'
                                        src={product.img} alt='Product'
                                    />
                                    <span className='uppercase transition-all ease-in-out duration-500 tracking-wide text-white bg-primary-100 px-2.5 py-1 rounded-lg mx-auto relative bottom-[40px] group-hover:bottom-[80px]'>
                                        {product.title}
                                    </span>
                                    <div className='transition-all ease-in-out duration-1000 h-px w-[70%] mx-auto bg-white/90 relative bottom-[52px] opacity-0 group-hover:opacity-100' />
                                </div>
                            </Link>
                            <p className='pt-1 px-2 leading-normal tracking-wide font-medium'>{t(product.description)}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Catalog;