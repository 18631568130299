import { FC } from 'react';
import ContactForm from '@components/Contact/ContactForm';
import ContactInfo from '@components/Contact/ContactInfo';
import { Namespace } from '@src/i18n/types';
import { useTranslation } from 'react-i18next';

const Contact: FC = () => {
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'Contacts' });

    return (
        <div>
            <div className='mx-auto max-w-6xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20'>
                <div className='mb-4'>
                    <div className='mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12'>
                        <h2 className='mb-4 font-bold tracking-tight text-primary-100 text-3xl sm:text-5xl'>
                            {t('Get in Touch')}
                        </h2>
                    </div>
                </div>
                <div className='flex items-stretch justify-center'>
                    <div className='grid md:grid-cols-2'>
                        <ContactInfo />
                        <ContactForm />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Contact;