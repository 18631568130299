import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CatalogData } from '@pages/interfaces';
import Carousel from '@components/Slider';
import Spinner from '@components/Spinner';
import { ERoutes } from '@constants/interfaces';
import { RiLeafFill } from "react-icons/ri";
import { FaTree } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { Namespace } from '@src/i18n/types';

const Home: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'Home' });

    const { data: catalogData, isLoading } = useQuery<CatalogData>({
        queryKey: ['catalog'],
        queryFn: async () => {
            const resp = await fetch('/data/catalog/info.json');
            return await resp.json();
        },
    });

    const brandInfo = [
        {
            Icon: RiLeafFill,
            title: t("Cosmetics or cosmeceuticals?"),
            description: t("Strict requirements for compliance with quality standards at the brand’s enterprises make it possible to bring product performance closer to medicinal cosmetics, which is preferred by professionals in the field of cosmetology. And many presenters classify such cosmetic products as cosmeceuticals")
        },
        {
            Icon: FaTree,
            title: t("Herbs of the Judean Desert"),
            description: t("Flawless skin, smooth and elastic, is always the basis of female beauty. Today, the trend is health and naturalness, which means that the use of truly effective and safe cosmetic care is of paramount importance for every woman")
        },
        {
            Icon: AiFillLike,
            title: t("Everything's under control!"),
            description: t("It was complete control over all stages of production at enterprises producing Onmakabim cosmetics, introduced back in 2000, that allowed the Israeli brand to make an incredible breakthrough and gain a leading position in the cosmetics market")
        },
    ];

    return (
        <>
            <div className="relative">
                <img src="/assets/home/main.jpeg" className="min-h-[390px]" alt="Main image" />
                <div className="absolute top-0 w-full h-full flex justify-center items-center flex-col bg-primary-100/[.12]">
                    <h1 className="text-4xl md:text-[55px] mx-auto font-bold [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">ON Floral Breeze</h1>
                    <p className="max-w-[350px] text-2xl md:text-3xl mt-3 font-medium [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
                        {t('The official representative of the cosmeceutical company ONmacabim Greece')}
                    </p>
                    <button
                        onClick={() => navigate('/about-us')}
                        className='mt-6 sm:mt-4 md:mt-8 text-xl md:text-3xl transition-colors duration-300 ease-in-out bg-primary-200 hover:bg-primary-300 text-white px-6 py-3 font-xl rounded-full sm:mb-0 disabled:opacity-70'
                    >
                        {t('Learn more')}
                    </button>
                </div>
            </div>
            <div className="mb-28 mt-16 md:mt-28">
                {isLoading ? <Spinner /> : (
                    <div>
                        <h3 className="mb-12 text-4xl font-semibold">{t('Our products')}</h3>
                        <Carousel>
                            {catalogData?.map(product => (
                                <div className='flex flex-col px-4' key={product.title}>
                                    <Link to={`${ERoutes.Catalog}/${product.title}`}>
                                        <div className='group relative cursor-pointer'>
                                            <img
                                                className='border-2 border-gray-100 rounded-xl transition-all ease-in-out duration-500 w-full group-hover:brightness-[.65]'
                                                src={product.img}
                                                alt='Product'
                                            />
                                            <span
                                                className='uppercase transition-all ease-in-out duration-500 tracking-wide text-white bg-primary-100 px-2.5 py-1 rounded-lg mx-auto relative bottom-[40px] group-hover:bottom-[80px]'>
                                                {product.title}
                                            </span>
                                            <div
                                                className='transition-all ease-in-out duration-1000 h-px w-[70%] mx-auto bg-white/90 relative bottom-[52px] opacity-0 group-hover:opacity-100' />
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                )}
            </div>
            <div className="my-28">
                <p className="mb-12 md:mb-16 text-4xl font-semibold px-8">{t('Advantages and features of ONmacabim')}:</p>
                <div className="mt-6 flex flex-wrap gap-y-12 xl:gap-y-0 justify-evenly xl:justify-between px-8 xl:px-0">
                    {brandInfo.map(({ Icon, title, description }) => (
                        <div className="rounded-lg shadow-2xl flex flex-col items-center p-6 md:p-8 max-w-[400px] border-b-8 border-primary-100" key={title}>
                            <Icon className="size-14 text-primary-100" />
                            <h4 className="text-3xl mt-3 font-medium">{title}</h4>
                            <p className="text-xl mt-4">{description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Home;