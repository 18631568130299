import Slider from "react-slick";
import { FC, ReactNode } from 'react';

const settings = {
    className: "center",
    centerMode: true,
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
            }
        }
    ]
};

const Carousel: FC<{ children: ReactNode[] | ReactNode }> = ({ children }) => (
    <div className="slider-container">
        <Slider {...settings}>
            {children}
        </Slider>
    </div>
);

export default Carousel;