import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ERoutes, NavigationItem } from '@constants/interfaces';
import { NavigationItems } from '@constants/navigation';
import { IoClose } from "react-icons/io5";
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { Namespace } from '@src/i18n/types';
import { useTranslation } from 'react-i18next';

const NavMenu: FC = () => {
    const { t } = useTranslation(Namespace.TRANSLATION, { keyPrefix: 'Navigation' });

    const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
    const [selectedNav, setSelectedNav] = useState<NavigationItem['title']>('Home');

    const { width } = useWindowDimensions();

    const handleSelectNavItem = (item: NavigationItem['title']) => {
        setSelectedNav(item);
        setIsNavOpen(false);
    };

    useEffect(() => {
        if (isNavOpen && width <= 1025) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [isNavOpen, width]);

    return (
        <div className='flex items-center justify-between border-b border-gray-400 pb-6 pt-2 md:py-8 px-4 xs:px-6 sm:px-12 xl:px-2'>
            <Link to={ERoutes.Home}>
                <img src='/assets/logo.png' alt='logo' className='w-32' />
            </Link>
            <nav>
                <section className='mobile-menu'>
                    <div
                        className='space-y-2 cursor-pointer'
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        <span className='block h-0.5 w-8 animate-pulse bg-gray-600'></span>
                        <span className='block h-0.5 w-8 animate-pulse bg-gray-600'></span>
                        <span className='block h-0.5 w-8 animate-pulse bg-gray-600'></span>
                    </div>
                    <div className={isNavOpen ? `bg-white flex flex-col z-10 items-center justify-evenly block absolute top-0 left-0 w-full h-[120vh]` : 'hidden'}>
                        <div
                            className='fixed top-0 right-0 p-12 cursor-pointer'
                            onClick={() => setIsNavOpen(false)}
                        >
                            <IoClose className='h-8 w-8 text-gray-600' />
                        </div>
                        <ul className='flex flex-col items-center justify-between min-h-[250px]'>
                            {NavigationItems.map(n => (
                                <li key={n.title} className={`border-b ${selectedNav === n.title ? 'text-primary-100 border-primary-200' : 'border-primary-200'} my-8 uppercase`} onClick={() => handleSelectNavItem(n.title)}>
                                    <Link to={n.path}>
                                        {t(n.title)}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>

                <ul className='desktop-menu'>
                    {NavigationItems.map(n => (
                        <li key={n.path} className='link' onClick={() => handleSelectNavItem(n.title)}>
                            <Link to={n.path}>
                                {t(n.title)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default NavMenu;