export enum ERoutes {
    Home = '/',
    Contact = '/contact',
    Catalog = '/catalog',
    CatalogOverview = '/catalog/:title',
    CatalogOverviewItem = '/catalog/:title/:id',
    AboutUs = '/about-us',
    AboutBrand = '/about-brand',
    NotFound = '*',
}

export type NavigationItem = {
    path: ERoutes;
    title: string;
}