import { forwardRef, HTMLInputAutoCompleteAttribute, HTMLInputTypeAttribute } from 'react';
import { ChangeHandler } from 'react-hook-form';

type InputProps = {
    onChange: ChangeHandler;
    type: HTMLInputTypeAttribute;
    placeholder: string;
    label: string;
    isError: boolean;
    error: any;
    autoComplete: HTMLInputAutoCompleteAttribute;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({onChange, type, placeholder, label, autoComplete, isError, error, ...props}, ref) => {
    return (
        <div className='mx-0 mb-1 sm:mb-4 flex flex-col items-baseline'>
            <label htmlFor={type} className='pb-2 text-sm uppercase tracking-wider text-primary-100'>{label}</label>
            <input
                type={type}
                id={type}
                autoComplete={autoComplete}
                placeholder={placeholder}
                className='mb-2 w-full rounded-md focus:outline-none focus:ring focus:ring-primary-100 bg-white border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0'
                name={type}
                ref={ref}
                {...props}
            />
            {isError ? <p className='mt-2 text-red-700'>{error.message}</p> : null}
        </div>
    );
});

export default Input;