import ScrollToTop from 'react-scroll-to-top';
import { FaArrowUp } from 'react-icons/fa';

const ScrollTop = () => (
    <ScrollToTop
        smooth
        color='#6f00ff'
        className='flex items-center justify-center !bottom-[100px]'
        component={<FaArrowUp color='black' />}
    />
);

export default ScrollTop;